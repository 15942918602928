// eslint-disable-next-line import/prefer-default-export,import/no-extraneous-dependencies
import Swal from 'sweetalert2'
import { axiosApiInstance } from '@core/auth/jwt/axiosApiInstance'
import { GetSmallImage } from '@core/utils/filter'

// eslint-disable-next-line import/prefer-default-export
export const alertError = error => {
  if (error.message === 'Network Error') {
    Swal.fire(
      {
        title: 'Ooops!',
        icon: 'error',
        confirmButtonText: 'Đồng ý',
        text: 'Vui lòng kiểm tra lại kết nối internet của bạn',
      },
    )
  }
}
export const alertError2 = (error, message) => {
  if (error === null) {
    Swal.fire(
      {
        title: 'Ooops!',
        icon: 'error',
        confirmButtonText: 'Đồng ý',
        text: message,
      },
    )
  } else if (error.message === 'Network Error') {
    Swal.fire(
      {
        title: 'Ooops!',
        icon: 'error',
        confirmButtonText: 'Đồng ý',
        text: 'Vui lòng kiểm tra lại kết nối internet của bạn',
      },
    )
  } else {
    Swal.fire(
      {
        title: 'Ooops!',
        icon: 'error',
        confirmButtonText: 'Đồng ý',
        text: message,
      },
    )
  }
}
export default {
  showMessageError(vm, message) {
    vm.$bvToast.toast(message, {
      title: 'Thông báo',
      variant: 'danger',
      toaster: 'b-toaster-top-right',
      solid: true,
    })
  },
  async excutePOST(vm, url, data, message) {
    const iResult = await axiosApiInstance.post(url, data)
      .then(res => {
        if (res.data.code === 200) {
          vm.$bvToast.toast(message, {
            title: 'Thông báo',
            variant: 'success',
            toaster: 'b-toaster-top-right',
            solid: true,
          })
          return true
        }
        alertError2(`${res.data?.errors[0]}`)
        return false
      })
      // eslint-disable-next-line no-shadow
      .catch(error => {
        alertError(error)
        return false
      })
    return iResult
  },
  async excutePUT(vm, url, data, message) {
    const iResult = await axiosApiInstance.put(url, data)
      .then(res => {
        if (res.data.code === 200) {
          vm.$bvToast.toast(message, {
            title: 'Thông báo',
            variant: 'success',
            toaster: 'b-toaster-top-right',
            solid: true,
          })
          return true
        }
        alertError2(`${res.data?.errors[0]}`)
        return false
      })
      // eslint-disable-next-line no-shadow
      .catch(error => {
        alertError(error)
        return false
      })
    return iResult
  },
  async excuteDELETE(vm, url, data, message) {
    const iResult = await axiosApiInstance.delete(url, { params: data })
      .then(response => {
        if (response.data.code === 200) {
          vm.$bvToast.toast(message, {
            title: 'Thông báo',
            variant: 'success',
            toaster: 'b-toaster-top-right',
            solid: true,
          })
          return true
        }
        alertError2(`${response.data?.errors[0]}`)
        return false
      })
      // eslint-disable-next-line no-shadow
      .catch(error => {
        alertError(error)
        return false
      })
    return iResult
  },
  async excuteGET(vm, url, data) {
    const iResult = await axiosApiInstance.get(url, { params: data })
      .then(response => {
        if (response.data.code === 200) {
          return response.data.data
        }
        alertError2(`${response.data?.errors[0]}`)
        return false
      })
      // eslint-disable-next-line no-shadow
      .catch(error => {
        alertError(error)
        return false
      })
    return iResult
  },
  async excuteGET_NoParam(vm, url) {
    // eslint-disable-next-line no-return-await
    return await axiosApiInstance.get(url)
      .then(response => {
        if (response.data.code === 200) {
          return response.data.data
        }
        alertError2(`${response.data?.errors[0]}`)
        return false
      })
      // eslint-disable-next-line no-shadow
      .catch(error => {
        alertError(error)
        return false
      })
  },
  async uploadServerFile(vm, url, model) {
    const sendForm = new FormData()
    sendForm.append('IsSecure', model.isSecure)
    sendForm.append('formFile', model.files)
    if (sendForm.fileType) sendForm.append('FileType', model.fileType)
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (userData) sendForm.append('UserId', userData.userId)
    try {
      const { data } = await axiosApiInstance.post(url, sendForm)
      return data
    } catch (err) {
      alertError(err)
      return false
    }
  },
  GetThumbnail(isFolder, fileExtension, filePath, size, serverFile, isLocal = true) {
    if (isFolder) {
      // eslint-disable-next-line global-require
      return require('@/assets/images/illustration/folder.png')
    }
    switch (fileExtension) {
      case '.doc':
      case '.docx':
        // eslint-disable-next-line global-require
        return require('@/assets/images/illustration/doc.svg')
      case '.ppt':
      case '.pptx':
        // eslint-disable-next-line global-require
        return require('@/assets/images/illustration/ppt.svg')
      case '.xls':
      case '.xlsx':
        // eslint-disable-next-line global-require
        return require('@/assets/images/illustration/xls.svg')
      case '.pdf':
        // eslint-disable-next-line global-require
        return require('@/assets/images/illustration/pdf.svg')
      case '.mp3':
        // eslint-disable-next-line global-require
        return require('@/assets/images/illustration/mp3.svg')
      case '.mp4':
        // eslint-disable-next-line global-require
        return require('@/assets/images/illustration/mp4.svg')
      case '.txt':
        // eslint-disable-next-line global-require
        return require('@/assets/images/illustration/txt.svg')
      case '.jpg':
        if (isLocal) {
          if (filePath) {
            const imageUrl = GetSmallImage(filePath, size, serverFile)
            if (imageUrl !== '') return imageUrl
          }
          // eslint-disable-next-line global-require
          return require('@/assets/images/illustration/jpg.svg')
        }
        return filePath
      case '.png':
        if (isLocal) {
          if (filePath) {
            const imageUrl = GetSmallImage(filePath, size, serverFile)
            if (imageUrl !== '') return imageUrl
          }
          // eslint-disable-next-line global-require
          return require('@/assets/images/illustration/png.svg')
        }
        return filePath
      case '.zip':
        // eslint-disable-next-line global-require
        return require('@/assets/images/illustration/zip.svg')
      default:
        // eslint-disable-next-line global-require
        return require('@/assets/images/illustration/other.svg')
    }
  },
  GetExtendSion(url) {
    const cut = url.split('.')
    return cut[cut.length - 1]
  },
  GetIsImageLocal(isType) {
    if (isType === '2') return false
    return isType !== '3'
  },
  GetExecuteAction(action, actions) {
    if (actions === null) return false
    return actions.find(x => x.id === action) !== undefined
  },
}
